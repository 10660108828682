/**
 * Generates array of numbers from the beggining to end
 * e.g range(1, 3) → [ 1, 2, 3 ]
 */
const range = (begin: number, end: number): number[] => {
  begin = Number(begin)
  end   = Number(end)

  if (Number.isNaN(begin) || Number.isNaN(end)) {
    throw new Error('array.range accepts two aguments, both should be numbers')
  }

  const isDownwards = begin > end
  const length = (isDownwards ? begin - end : end - begin) + 1

  return Array.from(Array(length).keys())
    .map((i) => isDownwards ? begin - i : begin + i)
}

const getArrayDepth = (value) => {
  return Array.isArray(value) ?
    1 + Math.max(...value.map(getArrayDepth)) :
    0
}

const sortByField = (array, field) => {
  return array.sort((a, b) => a[field] > b[field] ? 1 : a[field] === b[field] ? 0 : -1)
}

const shuffle = (array: any[]): any[] => {
  if (Array.isArray(array)) {
    let newArray = [ ...array ]

    // if array is suit for shuffle, it's small enougth, return it right away
    if (newArray.length <= 1) {
      return newArray
    }

    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [ newArray[i], newArray[j] ] = [ newArray[j], newArray[i] ]
    }

    if (newArray.join() === array.join()) {
      newArray.reverse()
    }

    return newArray
  }

  throw new Error('array.shuffle accepts only arrays in first argument')
}

/**
 * Returns new array with values merged by key
 */
const mergeByKey = <T1, T2>(array1: T1[], key1: string, array2: T2[], key2: string): Array<T1 | (T1 & T2)> => {
  const map = new Map<string, T1 | (T1 & T2)>()

  array1.forEach((item) => map.set(item[key1], item))
  array2.forEach((item) => map.set(item[key2], { ...map.get(item[key2]), ...item }))

  return Array.from(map.values())
}

/**
 * swap 2 elements by indexes
 */
const swapElements = (array, index1, index2) => {
  const temp = array[index1]
  array[index1] = array[index2]
  array[index2] = temp
  return array
}

export default {
  range,
  shuffle,
  getArrayDepth,
  sortByField,
  mergeByKey,
  swapElements,
}
